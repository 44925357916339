<template>
 <base-main-container>
    <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="68">
          AI is preparing your resource
        </v-progress-circular>
      </v-overlay>

       <template v-slot:centered>
      <v-card class="my-card rounded-lg" elevation="0">
        <v-card-title>FictionReflect: Fiction passages and stories with reading comprehension questions
        
        </v-card-title>
        <v-card-text>

         
          <v-text-field
            
            v-model="topic"
            class="ml-2"
            label="Specific Topic/Subject"
            outlined
          />
          <v-select
           
            class="mt-2 ml-2"
            append-icon="mdi-filter"
            v-model="grade"
            :items="[
              'K',
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
            ]"
            label="Suitable for Grade level"
            outlined
            clearable
          />

         
          <v-combobox
           
            class="mt-2 ml-2"
            append-icon="mdi-filter"
            v-model="type"
            :items="['Short Story', 'Poetry', 'Drama', 'Fable', 'Fairy Tale','Fantasy','Folklore','Historical Fiction']"
            label="Type"
            outlined
            clearable
             @input.native="type=$event.srcElement.value"
          />
         
          <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="quizItemCount"
              :items="['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
              label="Numer of Questions"
              outlined
              clearable
            />
          
        </v-card-text>
        <v-card-actions>
          <v-spacer />
 <v-btn class="mr-2" outlined link to="newresource">
              <v-icon class="mr-2"> mdi-backspace</v-icon> Cancel
            </v-btn>
          <v-btn
            :disabled="!((topic && type))
            "
            color="#212121"
            @click="sendRequest()"
          >
            <h3
              v-if="
               !((topic && type))
              "
            >
              Generate please
            </h3>
            <h3 v-else style="color: white">Generate please</h3>
          </v-btn>

          <v-spacer />
        </v-card-actions>
      </v-card>
       </template>
   <v-snackbar
          v-model="showSnackbar"
          :timeout="6000"
           color="warning"
          top
          
        >
          {{ snackbarMessege }}
        </v-snackbar>
  </base-main-container>
</template>

<script>

import { getFunctions, httpsCallable } from "firebase/functions";

import { firebaseAuth } from "../../firebase";
import { db } from "../../firebase";

export default {
 
  data() {
    return {
      what: "Fiction",
      topic: null,
      grade: null,
      quizItemCount:0,
     
      isLoading: false,
     
      showSnackbar:false,
      snackbarMessege:"",
      
    };
  },
  // computed:{
  //  premium(){
  //     return this.$store.state.subscription ? this.$store.state.subscription.status =='active' 
  //     || this.$store.state.subscription.status == 'active school'
  //      || this.$store.state.subscription.status == 'trialing'
  //     : false
  //   }
  // },

  methods: {
  
    async sendRequest() {
      this.isLoading = true;
      this.grade = this.grade
        ?   this.grade+ "grade level students"
        : "not a specific grade or age level";
     
     
let uid = firebaseAuth.currentUser.uid;
let newresource ={
    what: this.what,
        topic: this.topic,
        grade: this.grade,
        lastsaved: Date.now(), 
        promt: [
    { "role": "system", "content": "You are an excellent researcher and writer." },
    { "role": "user", "content": "Can you give me a fiction "+ this.type + "on this topic: "+this.topic+" Please also add "+this.quizItemCount+ "  multiple choicce question to check undrstanding. Please make shure the language level is appropriate for "+this.grade }
],
        uid:uid    
}
try {
          const userRef = db.collection("customers").doc(uid);
          const subcollectionRef = userRef.collection("saved");

          const docRef = await subcollectionRef.add(newresource)
          //console.log(docRef.id);
          newresource.id=docRef.id
        } catch (error) {
          console.error("Error adding document:", error);
        }

const functions = getFunctions();

      try { 
      const functionRef = httpsCallable(
        functions,
        "callopenai"
      );
      functionRef(newresource);
       
        this.isLoading = false;
        this.saveDialog = false;
        this.$router.push("/mydocs");

}catch (error) {
      this.isLoading = false;
      this.showSnackbar = true
      this.snackbarMessege = "The system is overloaded, please try again" 
}

    },

    clearSelections() {
      (this.topic = null),
        (this.grade = null),
        (this.genre = null),
        (this.type = null),
        (this.characters = null),
        (this.voice = null)
    },
     
  },
  
};
</script>

<style scoped>

    .centered {
        border-radius: 15px;  
        background-color:#f3f6fc; 
        display: flex;
        justify-content: center;
        max-width: 1200px;
        height: 90vh; /* Adjust this value to match your desired height */
       
    }
    @media (min-width: 600px) {
  .my-card {
  
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
   
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
   
    width: 800px;
    
  }
}
    .container.fill-height {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}


</style>




